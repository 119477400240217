import * as React from 'react'
import { Box, Chip, Stack, TextField } from '@mui/material'
import produce from 'immer'
import { FormattedMessage } from 'react-intl'

export default function TagFilter({ item, setState, choice }) {
  return (
    <Stack direction="row" spacing={2} sx={{ my: 2 }} alignItems="center">
      <Box>
        <FormattedMessage id="tag.filter_tags" />
      </Box>
      <FormattedMessage id="tag.enter_tags">
        {(placeholder) => (
          <TextField
            variant="standard"
            size="small"
            sx={{
              margin: '1rem 0',
              '.stack': {
                my: 1
              }
            }}
            margin="none"
            placeholder={placeholder}
            value={choice?.tag}
            onChange={(e) => {
              setState(
                produce((draft) => {
                  const currentItem = draft.items.find((i) => i.id === item.id)
                  const currentChoice = currentItem.choices.find(
                    (i) => i.id === choice.id
                  )
                  currentChoice.tag = e.target.value
                })
              )
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setState(
                  produce((draft) => {
                    const currentItem = draft.items.find(
                      (i) => i.id === item.id
                    )
                    const currentChoice = currentItem.choices.find(
                      (i) => i.id === choice.id
                    )
                    if (!currentChoice.tags) {
                      currentChoice.tags = [currentChoice.tag]
                    } else {
                      currentChoice.tags = [
                        ...new Set([...currentChoice.tags, currentChoice.tag])
                      ]
                    }
                    currentChoice.tag = ''
                  })
                )
              }
            }}
            InputProps={{
              startAdornment: (
                <Stack
                  direction="row"
                  spacing={1}
                  className="stack"
                  sx={{ mr: 2 }}>
                  {choice?.tags?.map((tag) => (
                    <Chip
                      label={tag}
                      key={tag}
                      onDelete={() => {
                        setState(
                          produce((draft) => {
                            const currentItem = draft.items.find(
                              (i) => i.id === item.id
                            )
                            const currentChoice = currentItem.choices.find(
                              (i) => i.id === choice.id
                            )
                            currentChoice.tags = currentChoice.tags.filter(
                              (i) => i !== tag
                            )
                          })
                        )
                      }}
                    />
                  ))}
                </Stack>
              )
            }}
          />
        )}
      </FormattedMessage>
    </Stack>
  )
}
