import React, { useState, useCallback, useMemo } from 'react'
import isHotkey from 'is-hotkey'
import { Editor, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, withReact, useSlate, Slate } from 'slate-react'

import { Button, Icon, Toolbar } from './components'
import { Box, Select, MenuItem } from '@mui/material'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
}

const CompactRichText = ({ fontColor, value = [], setValue = () => {} }) => {
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [defaultFontColor, setDefaultFontColor] = useState(fontColor)

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }

  const toggleFontSize = (editor, size) => {
    const isActive = isFontSizeActive(editor, size)
    if (isActive) {
      Editor.removeMark(editor, 'fontSize')
    } else {
      Editor.addMark(editor, 'fontSize', size)
    }
  }

  const toggleFontColor = (editor, color) => {
    const isActive = isFontColorActive(editor, color)
    if (isActive) {
      Editor.removeMark(editor, 'fontColor')
    } else {
      Editor.addMark(editor, 'fontColor', color)
    }
  }

  const FontSizeSelect = ({ editor }) => {
    const fontSizes = [12, 14, 16, 18, 20, 22, 24, 32]
    const [currentSize, setCurrentSize] = useState(22)

    return (
      <Select
        value={currentSize}
        style={{ height: '30px', fontSize: 'small', color: 'gray' }}
        sx={{
          '& .css-qiwgdb': {
            paddingTop: '5px',
            paddingBottom: '0px',
            paddingRight: '40px',
            marginBottom: '-4px',
            width: '40px'
          }
        }}
        onChange={(event) => {
          toggleFontSize(editor, event.target.value)
          setCurrentSize(event.target.value)
        }}>
        {fontSizes.map((size) => (
          <MenuItem
            value={size}
            style={{ fontSize: 'small', paddingTop: '6px', color: 'gray' }}>
            {size}px
          </MenuItem>
        ))}
      </Select>
    )
  }

  const FontColorPicker = ({ editor }) => {
    return (
      <input
        type="color"
        style={{ height: '30px', width: '30px' }}
        onChange={(event) => toggleFontColor(editor, event.target.value)}
      />
    )
  }

  const isFontSizeActive = (editor, size) => {
    const marks = Editor.marks(editor)
    return marks ? marks.fontSize === size : false
  }

  const isFontColorActive = (editor, color) => {
    const marks = Editor.marks(editor)
    return marks ? marks.fontColor === color : false
  }

  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    return (
      <p style={style} {...attributes}>
        {children}
      </p>
    )
  }

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.code) {
      children = <code>{children}</code>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    if (leaf.underline) {
      children = <u>{children}</u>
    }

    if (leaf.fontSize) {
      children = (
        <span
          style={{
            fontSize: `${leaf.fontSize}px`
          }}>
          {children}
        </span>
      )
    }

    if (leaf.fontColor) {
      children = (
        <span
          style={{
            color: leaf.fontColor
          }}>
          {children}
        </span>
      )
    } else {
      children = (
        <span
          style={{
            color: defaultFontColor
          }}>
          {children}
        </span>
      )
    }

    return <span {...attributes}>{children}</span>
  }

  const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, format)
        }}>
        <Icon>{icon}</Icon>
      </Button>
    )
  }

  return (
    <Box
      style={{
        width: '100%',
        paddingLeft: '15px',
        paddingRight: '15px'
      }}>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          const isAstChange = editor.operations.some(
            (op) => 'set_selection' !== op.type
          )
          if (isAstChange) {
            setValue(value)
          }
        }}>
        <Toolbar
          style={{
            paddingBottom: '5px',
            marginBottom: 0,
            border: 0,
            display: 'flex'
          }}>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <FontSizeSelect editor={editor} />
          <FontColorPicker editor={editor} />
        </Toolbar>
        <Box
          sx={{
            border: 1.5,
            borderRadius: '8px',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginLeft: '-20px',
            marginRight: '-20px'
          }}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={'Enter some text…'}
            spellCheck
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
          />
        </Box>
      </Slate>
    </Box>
  )
}

export default CompactRichText
