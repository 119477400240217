import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routes } from '../constants'

export default function NoMatch() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        marginTop: '50vh'
      }}>
      <Box sx={{ my: 3 }}>
        <FormattedMessage id="error.page_not_found" />
      </Box>
      <Button onClick={() => navigate(routes.login)}>
        <FormattedMessage id="general.return_home" />
      </Button>
    </Box>
  )
}
