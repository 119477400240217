import escapeHtml from 'escape-html'
import { Text } from 'slate'

export const isResponseOk = (response) => {
  if (response.status >= 200 && response.status <= 299) {
    return response.json()
  } else {
    throw Error(response.statusText)
  }
}

export const serialize = (node) => {
  let nodeText = escapeHtml(node.text)
  if (Text.isText(node)) {
    if (node['bold']) {
      nodeText = `<strong>` + nodeText + `</strong>`
    }

    if (node['italic']) {
      nodeText = `<em>` + nodeText + `</em>`
    }

    if (node['underlined']) {
      nodeText = `<u>` + nodeText + `</u>`
    }
    // Other marks should go here like above

    return nodeText
  }

  if (Array.isArray(node)) {
    return node.map((subNode) => serializeSubNode(subNode)).join('')
  }

  return serializeSubNode(node)
}

const serializeSubNode = (node) => {
  const children = node.children.map((n) => serialize(n)).join('')
  switch (node.type) {
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    default:
      return `<p>${children}</p>`
  }
}
