import { colors } from '../constants'
import Box from '@mui/material/Box'
import React from 'react'

const RespondentCountTags = ({ item }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        mr: 2,
        flexDirection: 'column',
        justifyItems: 'stretch',
        alignItems: 'stretch',
        maxHeight: '100%',
        overflowY: 'hidden',
        width: 70,
        my: -2,
        '.innerbox': {
          opacity: 0.9,
          px: '3px',
          maxHeight: 18,
          fontSize: 10,
          my: '1px',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          color: colors.white
        }
      }}>
      <Box
        className="innerbox"
        sx={{
          bgcolor: colors.green
        }}>
        <b>submit:</b>
        <b>{item?.engagement?.Completed}</b>
      </Box>
      <Box
        className="innerbox"
        sx={{
          bgcolor: colors.darkgrey
        }}>
        <b>open:</b>
        <b>{item?.engagement?.Opened}</b>
      </Box>
      <Box
        className="innerbox"
        sx={{
          bgcolor: colors.red
        }}>
        <b>email:</b>
        <b>{item?.engagement?.Ignored}</b>
      </Box>
    </Box>
  )
}

export default RespondentCountTags
