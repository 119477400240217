import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Papa from 'papaparse'
import { useDropzone } from 'react-dropzone'
import useStore from '../../store'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import InputEmailField from '../../components/InputEmailField'
import InputTextField from '../../components/InputTextField'
import SimpleDialog from '../../components/SimpleDialog'
import RichText from '../../components/Editor'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Link as LinkIcon
} from '@mui/icons-material'
import api from '../../api'
import { defaultFromEmail } from '../../constants'
import { useSnackbar } from 'notistack'
import Loading from '../../components/Loading'
import produce from 'immer'
import { serialize } from '../../helpers'

const StyledBox = styled(Box)`
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }

  .email-list {
    height: 600px;
    margin-top: 20px;
    border: 2px solid lightgrey;
    overflow-y: scroll;
    display: flex;
    .MuiList-root {
      padding: 0;
    }
  }
`

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsText(file, 'UTF-8')
  })
}

const isCSVFile = (file) => {
  const fileExtension = file.name.split('.').pop().toLowerCase()
  // Microsoft automatically converts csv type to vnd.ms-excel
  const validMimeType =
    file.type === 'text/csv' || file.type === 'application/vnd.ms-excel'
  return fileExtension === 'csv' && validMimeType
}

const parseCSVEmailList = (parsedObj) => {
  let errorMessages = []
  let validEmails = []
  let columnTitles = Object.keys(parsedObj[0])

  if (!columnTitles.includes('email')) {
    errorMessages.push(<FormattedMessage id="error.csv.missing_col" />)
  } else {
    parsedObj.forEach((row) => {
      const regex = /\S+@\S+\.\S+/
      if (regex.test(row.email)) {
        let emailObj = {}
        emailObj.name = row.name || ''
        emailObj.email = row.email
        validEmails.push(emailObj)
      } else {
        errorMessages.push(
          <FormattedMessage
            id="error.invalid_email"
            values={{ email: row.email }}
          />
        )
      }
    })
  }
  return { errorMessages, validEmails }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { surveyId, collectorId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  const [newEmail, setNewEmail] = useState({ val: '', isValid: false })
  const [name, setName] = useState({ val: '', isValid: false })
  const [weblinks, setWeblinks] = useState({})
  const [dialog, setDialog] = useState({
    open: false,
    scheduled: false,
    completed: false,
    opened: false,
    notopened: false,
    notcompleted: false,
    all: false
  })
  const [dialogTestEmail, testEmailDialog] = useState({ open: false })
  const [schedule, setSchedule] = useState(dayjs().add(1, 'day'))
  const [collecting, setCollecting] = useState(false)
  const [showPublicURL, setShowPublicURL] = useState(false)
  const [publicWebLink, setPublicWebLink] = useState()

  const [collector, setCollector] = useState(null)
  const surveys = useStore((state) => state.surveys)

  const currentSurvey = surveys.find((s) => s.id === parseInt(surveyId))

  useEffect(() => {
    const getCollector = async () => {
      if (!collector) {
        try {
          const collectorList = await api.getCollectorsForSurvey(surveyId)
          const currentCollector = collectorList.find(
            (collector) => collector.id === parseInt(collectorId)
          )

          if (!('meta' in currentCollector) || currentCollector.meta == null) {
            currentCollector.meta = {
              header: 'Welcome to Prompta.ai',
              email_html: '<p></p>',
              from_email: 'surveys@prompta.ai',
              button_text: 'Begin Survey',
              show_button: true,
              email_subject: 'We want your opinion'
            }
          }

          if (!('email_list' in currentCollector)) {
            currentCollector.email_list = []
          }

          if (!currentCollector.title) {
            currentCollector.title = `new-collector-${collectorId}`
          }

          setCollector(currentCollector)
        } catch (error) {
          console.error('Error fetching collectors:', error)
        }
      }
    }

    getCollector()
  }, [])

  useEffect(() => {
    if (currentSurvey !== null)
      setShowPublicURL(currentSurvey.publicly_available)
  }, [currentSurvey])

  useEffect(() => {
    ;(async () => {
      if (acceptedFiles[0] !== undefined) {
        let content = await readFileAsync(acceptedFiles[0])
        if (acceptedFiles[0].type === 'application/json') {
          content = JSON.parse(content)
          setCollector({ ...collector, meta: content.meta })
          return
        }

        if (isCSVFile(acceptedFiles[0])) {
          Papa.parse(content, {
            header: true,
            skipEmptyLines: true,
            // functions performed after parsing
            complete: function (results) {
              let { errorMessages, validEmails } = parseCSVEmailList(
                results.data
              )
              errorMessages.forEach((error) => {
                statusPopup(error, 'error')
              })

              if (collector?.email_list) {
                validEmails = validEmails.filter(
                  (i) => !collector?.email_list.some((f) => f.email === i.email)
                )
              }

              if (validEmails.length < 1) {
                statusPopup(
                  <FormattedMessage id="collector.no_new_email" />,
                  'warning'
                )
              } else {
                statusPopup(
                  <FormattedMessage
                    id="collector.emails_added"
                    values={{ emails: validEmails.length }}
                  />,
                  'success'
                )
              }
              setCollector({
                ...collector,
                email_list: [...collector?.email_list, ...validEmails]
              })
            }
          })
        } else {
          statusPopup(<FormattedMessage id="error.unknown_format" />, 'error')
        }
      }
    })()
  }, [acceptedFiles])

  const emails = collector?.email_list || []

  const statusPopup = (text, style) => {
    enqueueSnackbar(text, {
      variant: style,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    })
  }

  const objectIncludes = (array, key, value) => {
    return array.some((object) => object[key] === value)
  }

  const saveCollector = async () => {
    let upgraded = { ...collector }
    upgraded.meta = {
      header: 'Welcome to Prompta.ai',
      ...upgraded.meta,
      email_html: serialize(
        upgraded?.meta?.email_body || [
          {
            type: 'paragraph',
            children: [{ text: '' }]
          }
        ]
      ),
      button_text: upgraded?.meta?.button_text,
      show_button: !!upgraded?.meta?.show_button,
      email_subject: upgraded?.meta?.email_subject,
      from_email: upgraded?.meta?.from_email
    }

    upgraded.email_list = emails
    upgraded.survey = parseInt(surveyId)

    currentSurvey.collectors.forEach((collector) => {
      if (
        collector.title.trim() === upgraded.title.trim() &&
        collector.id !== upgraded.id
      ) {
        statusPopup(
          <FormattedMessage id="collector.duplicate_title" />,
          'warning'
        )
        return
      }
    })

    await api.saveCollector(surveyId, collectorId, upgraded)
    statusPopup(<FormattedMessage id="collector.collector_saved" />, 'success')
  }

  const isEmptyCollectorTitle = () => {
    if (!collector.title || collector.title.trim() === '') return false
    return true
  }

  const collectPublicSurvey = async () => {
    const data = await api.collectPublicSurvey(surveyId, collectorId)
    statusPopup(data.msg, 'success')
  }

  const preventManualInput = (event) => {
    event.preventDefault()
  }

  if (!collector || !currentSurvey) {
    return <Loading />
  }

  // todo: https://mui.com/material-ui/react-list/
  return (
    <StyledBox>
      <Box
        sx={{
          display: 'flex',
          mb: 3,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Typography variant="h5" sx={{ color: 'dimgrey' }}>
          {<FormattedMessage id="collector.collecting_survey" />}: &nbsp;
          {currentSurvey.title}
        </Typography>
        <Box>
          <Tooltip title={<FormattedMessage id="tooltip.download_emails" />}>
            <IconButton
              aria-label="download"
              onClick={() => {
                const encoder = new TextEncoder()
                const data = encoder.encode(JSON.stringify(collector, null, 4))
                const blob = new Blob([data], { type: 'application/json' })
                const url = URL.createObjectURL(blob)
                const element = document.createElement('a')
                element.href = url
                element.download = 'collector.json'
                document.body.appendChild(element)
                element.click()
              }}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TextField
        sx={{ my: 2 }}
        fullWidth
        required
        label={<FormattedMessage id="collector.title" />}
        id="outlined-size-normal"
        value={collector.title}
        onChange={(event) =>
          setCollector({ ...collector, title: event.target.value })
        }
      />
      {!showPublicURL && (
        <Box>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>
                <FormattedMessage id="input.drag_drop_csv" />
              </p>
            </div>
          </section>
          <Stack direction="row" alignItems="flex-top" sx={{ my: 4 }}>
            <InputEmailField
              autoComplete="off"
              handleChange={(val, isValid) => setNewEmail({ val, isValid })}
              onEnter={() => {
                if (!objectIncludes(emails, 'email', newEmail.val.trim())) {
                  setCollector(
                    produce((draft) => {
                      draft.email_list = [
                        ...emails,
                        {
                          email: newEmail.val.trim(),
                          name: ''
                        }
                      ]
                    })
                  )
                } else {
                  statusPopup(
                    <FormattedMessage
                      id="error.email_already_exists"
                      values={newEmail.val.trim()}
                    />,
                    'warning'
                  )
                }
              }}
            />
            <Button
              sx={{ maxHeight: 45 }}
              disabled={!newEmail.isValid}
              onClick={() => {
                const trimmedEmail = newEmail.val.trim()
                if (!objectIncludes(emails, 'email', trimmedEmail)) {
                  setCollector(
                    produce((draft) => {
                      draft.email_list = [
                        ...emails,
                        {
                          email: trimmedEmail,
                          name: ''
                        }
                      ]
                    })
                  )
                } else {
                  statusPopup(
                    `Email '${trimmedEmail}' already exists.`,
                    'warning'
                  )
                }
              }}>
              <FormattedMessage id="general.add" />
            </Button>
          </Stack>
          <Box className="email-list">
            <List sx={{ width: '100%', maxWidth: 1 }}>
              {emails.length === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 1
                  }}>
                  <FormattedMessage id="collector.empty_email_list" />
                </Box>
              )}
              {emails.map(({ email: value, name }, index) => (
                <ListItem
                  sx={{
                    borderBottom: '1px solid lightgrey',
                    px: 2
                  }}
                  key={value}
                  disableGutters>
                  <ListItemText
                    primary={`${index + 1}) ${value}`}
                    sx={{ maxWidth: '400px' }}
                  />
                  <Input
                    value={name || ''}
                    sx={{ width: 200 }}
                    onChange={(e) => {
                      setCollector(
                        produce((draft) => {
                          const item = draft.email_list.find(
                            (i) => i?.email === value
                          )
                          item.name = e.target.value || ''
                        })
                      )
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }}></Box>
                  {weblinks[value] ? (
                    <IconButton
                      aria-label="copy"
                      onClick={() => {
                        try {
                          navigator.clipboard.writeText(weblinks[value])
                          statusPopup(
                            <FormattedMessage id="collector.copied_weblink" />,
                            'success'
                          )
                        } catch (e) {
                          statusPopup(
                            <FormattedMessage id="error.not_copied" />,
                            'error'
                          )
                        }
                      }}>
                      <ContentCopyIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="copy"
                      onClick={async () => {
                        const { weblink } = await api.generateWebLink(
                          surveyId,
                          collectorId,
                          value
                        )
                        setWeblinks({ ...weblinks, [value]: weblink })
                      }}>
                      <LinkIcon />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setCollector(
                        produce((draft) => {
                          draft.email_list = draft.email_list.filter(
                            (_, i) => i !== index
                          )
                        })
                      )
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="collector.from_email" />
            </Box>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={collector?.meta?.from_email || defaultFromEmail}
              onChange={(event) => {
                setCollector(
                  produce((draft) => {
                    draft.meta = {
                      ...draft?.meta,
                      from_email: event.target.value
                    }
                  })
                )
              }}
              fullWidth
              label="Email">
              <MenuItem value={'tim@prompta.ai'}>tim@prompta.ai</MenuItem>
              <MenuItem value={'surveys@prompta.ai'}>
                surveys@prompta.ai
              </MenuItem>
            </Select>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="collector.email_subject" />
            </Box>
            <TextField
              fullWidth
              error={collector?.meta?.email_subject.trim() === ''}
              value={collector?.meta?.email_subject}
              onChange={(event) => {
                setCollector(
                  produce((draft) => {
                    draft.meta = {
                      ...draft?.meta,
                      email_subject: event.target.value
                    }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.header" />
            </Box>
            <TextField
              fullWidth
              error={collector?.meta?.header.trim() === ''}
              value={collector?.meta?.header}
              onChange={(event) => {
                setCollector(
                  produce((draft) => {
                    draft.meta = { ...draft?.meta, header: event.target.value }
                  })
                )
              }}
            />
          </Stack>
          <Box sx={{ p: 1, my: 2, minHeight: 300 }}>
            <RichText
              value={
                collector?.meta?.email_body || [
                  {
                    type: 'paragraph',
                    children: [{ text: '' }]
                  }
                ]
              }
              setValue={(value) => {
                setCollector(
                  produce((draft) => {
                    draft.meta = { ...draft?.meta, email_body: value }
                  })
                )
              }}
            />
          </Box>
          <Stack direction="row" sx={{ my: 3 }} spacing={3} alignItems="center">
            <Box>
              <FormattedMessage id="collector.show_button" />
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                <FormattedMessage id="general.off" />
              </Typography>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={collector?.meta?.show_button}
                onChange={(event) => {
                  setCollector(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        show_button: event.target.checked
                      }
                    })
                  )
                }}
              />
              <Typography>
                <FormattedMessage id="general.on" />
              </Typography>
            </Stack>
          </Stack>
          {collector?.meta?.show_button && (
            <Stack
              direction="row"
              spacing={1}
              sx={{ my: 2 }}
              alignItems="center">
              <Box width="50%">
                <FormattedMessage id="collector.begin_survey_text" />
              </Box>
              <TextField
                label={<FormattedMessage id="collector.begin_survey_text" />}
                fullWidth
                value={collector?.meta?.button_text}
                onChange={(event) => {
                  setCollector(
                    produce((draft) => {
                      draft.meta = {
                        ...draft?.meta,
                        button_text: event.target.value
                      }
                    })
                  )
                }}
              />
            </Stack>
          )}
        </Box>
      )}

      {showPublicURL && (
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <Box width="9%">
            <FormattedMessage id="collector.survey_url" />
          </Box>
          {publicWebLink ? (
            <IconButton
              aria-label="copy"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(publicWebLink)
                  statusPopup(
                    <FormattedMessage id="collector.copied_weblink" />,
                    'success'
                  )
                } catch (e) {
                  statusPopup(
                    <FormattedMessage id="error.not_copied" />,
                    'error'
                  )
                }
              }}>
              <ContentCopyIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="copy"
              onClick={async () => {
                const { weblink } = await api.generatePublicWebLink(
                  surveyId,
                  collectorId
                )
                setPublicWebLink(weblink)
              }}>
              <LinkIcon />
            </IconButton>
          )}
        </Stack>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          my: 2
        }}>
        <Box></Box>
        <Stack direction="row" spacing={2}>
          {!showPublicURL && (
            <Button
              variant="contained"
              color="success"
              onClick={async () => {
                testEmailDialog({
                  ...testEmailDialog,
                  open: true,
                  scheduled: true
                })
              }}>
              <FormattedMessage id="input.test_email" />
            </Button>
          )}
          <Tooltip
            title={
              !isEmptyCollectorTitle() ? (
                <FormattedMessage id="error.collector_title_missing" />
              ) : (
                ''
              )
            }>
            <span>
              <Button
                variant="contained"
                color="success"
                disabled={!isEmptyCollectorTitle()}
                onClick={saveCollector}>
                <FormattedMessage id="general.save" />
              </Button>
            </span>
          </Tooltip>
          {!showPublicURL && (
            <Button
              variant="contained"
              onClick={async () => {
                setDialog({ ...dialog, open: true, scheduled: true })
              }}>
              {collecting ? (
                <Loading color="white" />
              ) : (
                <FormattedMessage id="general.schedule" />
              )}
            </Button>
          )}
          {!showPublicURL && (
            <Button
              variant="contained"
              onClick={async () => {
                setDialog({ ...dialog, open: true, scheduled: false })
              }}>
              {collecting ? (
                <Loading color="white" />
              ) : (
                <FormattedMessage id="general.collect" />
              )}
            </Button>
          )}
          {showPublicURL && (
            <Button variant="contained" onClick={collectPublicSurvey}>
              <FormattedMessage id="general.collect" />
            </Button>
          )}
        </Stack>
      </Box>

      <SimpleDialog
        ok={<FormattedMessage id="general.send" />}
        cancel={<FormattedMessage id="general.cancel" />}
        content={
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {dialog.scheduled && (
              <>
                <Box sx={{ my: 2 }}>
                  <FormattedMessage id="collector.schedule_email_date" />
                </Box>
                <DateTimePicker
                  label={<FormattedMessage id="collector.send_date" />}
                  value={schedule}
                  onChange={setSchedule}
                  renderInput={(params) => (
                    <TextField {...params} onKeyDown={preventManualInput} />
                  )}
                  minDateTime={dayjs()}
                  disablePast={true}
                />
              </>
            )}
            <Divider sx={{ my: 2 }} />
            <Box>
              <FormattedMessage id="collector.set_expiration" />
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                <FormattedMessage id="general.off" />
              </Typography>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={!!dialog.expired_by}
                onChange={(event) => {
                  setDialog({
                    ...dialog,
                    expired_by:
                      event.target.checked &&
                      Date.now() + 7 * 24 * 60 * 60 * 1000
                  })
                }}
              />
              <Typography>
                <FormattedMessage id="general.on" />
              </Typography>
            </Stack>

            {dialog.expired_by && (
              <DateTimePicker
                label={<FormattedMessage id="collector.expiration_date" />}
                value={dialog.expired_by}
                onChange={(val) => setDialog({ ...dialog, expired_by: val })}
                renderInput={(params) => (
                  <TextField {...params} onKeyDown={preventManualInput} />
                )}
                minDateTime={dayjs()}
                disablePast={true}
              />
            )}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ my: 1 }}>
              <FormattedMessage id="collector.recipients" />
            </Box>
            <Stack direction="column" spacing={1} flexWrap={'wrap'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Checkbox
                  checked={dialog.all}
                  onChange={(event) => {
                    setDialog({
                      ...dialog,
                      ...(event.target.checked
                        ? {
                            completed: false,
                            opened: false,
                            notopened: false,
                            notcompleted: false,
                            all: false
                          }
                        : {}),
                      all: event.target.checked
                    })
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box>
                  <FormattedMessage id="collector.everyone" />
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Checkbox
                  checked={dialog.completed}
                  onChange={(event) => {
                    setDialog({
                      ...dialog,
                      ...(event.target.checked
                        ? {
                            completed: false,
                            opened: false,
                            notopened: false,
                            notcompleted: false,
                            all: false
                          }
                        : {}),
                      completed: event.target.checked
                    })
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box>
                  <FormattedMessage id="collector.only_completed" />
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Checkbox
                  checked={dialog.opened}
                  onChange={(event) => {
                    setDialog({
                      ...dialog,
                      ...(event.target.checked
                        ? {
                            completed: false,
                            opened: false,
                            notopened: false,
                            notcompleted: false,
                            all: false
                          }
                        : {}),
                      opened: event.target.checked
                    })
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box>
                  <FormattedMessage id="collector.only_opened" />
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Checkbox
                  checked={dialog.notopened}
                  onChange={(event) => {
                    setDialog({
                      ...dialog,
                      ...(event.target.checked
                        ? {
                            completed: false,
                            opened: false,
                            notopened: false,
                            notcompleted: false,
                            all: false
                          }
                        : {}),
                      notopened: event.target.checked
                    })
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box>
                  <FormattedMessage id="collector.only_not_opened" />
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Checkbox
                  checked={dialog.notcompleted}
                  onChange={(event) => {
                    setDialog({
                      ...dialog,
                      ...(event.target.checked
                        ? {
                            completed: false,
                            opened: false,
                            notopened: false,
                            notcompleted: false,
                            all: false
                          }
                        : {}),
                      notcompleted: event.target.checked
                    })
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box>
                  <FormattedMessage id="collector.only_not_completed" />
                </Box>
              </Stack>
            </Stack>
          </LocalizationProvider>
        }
        onConfirm={async () => {
          const {
            opened,
            completed,
            expired_by,
            all,
            notopened,
            notcompleted
          } = dialog
          if (!opened && !completed && !all && !notopened && !notcompleted) {
            statusPopup(<FormattedMessage id="error.no_recipients" />, 'error')
            return
          } else if (emails.length < 1) {
            statusPopup(
              <FormattedMessage id="collector.empty_email_list" />,
              'error'
            )
            return
          }
          setCollecting(true)
          setDialog({
            open: false,
            scheduled: false,
            completed: false,
            opened: false,
            notopened: false,
            notcompleted: false,
            all: false
          })
          await saveCollector()
          await api.collectSurvey(surveyId, collectorId, {
            emails: [...new Set(emails.filter(Boolean))],
            ...(dialog.scheduled && { schedule: dayjs(schedule).unix() }),
            opened: opened,
            completed: completed,
            notopened: notopened,
            notcompleted: notcompleted,
            all: all,
            ...(dialog.expired_by && { expired_by: dayjs(expired_by).unix() })
          })
          setCollecting(false)
          statusPopup(
            <FormattedMessage id="collector.emails_sent" />,
            'success'
          )
        }}
        open={dialog.open}
        title={
          <FormattedMessage id="collector.send_for">
            {(headerText) => new String(headerText + ' ' + collector.title)}
          </FormattedMessage>
        }
        setOpen={(open) => setDialog({ ...dialog, open })}
      />

      <SimpleDialog
        ok={<FormattedMessage id="general.send" />}
        cancel={<FormattedMessage id="general.cancel" />}
        open={dialogTestEmail.open}
        title={
          <FormattedMessage id="collector.send_for">
            {(headerText) => new String(headerText + ' ' + collector.title)}
          </FormattedMessage>
        }
        setOpen={(open) => testEmailDialog({ ...dialogTestEmail, open })}
        content={
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ my: 2 }}>
              <FormattedMessage id="input.test_email" />
            </Box>
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
              sx={{ my: 2 }}>
              <InputTextField
                helperText=""
                autoComplete="off"
                handleChange={(val, isValid) => setName({ val, isValid })}
              />
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
              sx={{ my: 2 }}>
              <InputEmailField
                helperText=""
                autoComplete="off"
                handleChange={(val, isValid) => setNewEmail({ val, isValid })}
              />
            </Stack>
          </LocalizationProvider>
        }
        onConfirm={async () => {
          testEmailDialog({ open: false })
          let upgraded = { ...collector }
          upgraded.meta = {
            header: 'Welcome to Prompta.ai',
            ...upgraded.meta,
            email_html: serialize(
              upgraded?.meta?.email_body || [
                {
                  type: 'paragraph',
                  children: [{ text: '' }]
                }
              ]
            ),
            button_text: upgraded?.meta?.button_text || 'Begin Survey',
            show_button: !!upgraded?.meta?.show_button,
            email_subject: upgraded?.meta?.email_subject || 'Testing survey',
            from_email: upgraded?.meta?.from_email || defaultFromEmail,
            to_email: newEmail.val,
            survey_id: surveyId,
            collector_id: collectorId,
            name: name.val
          }
          const sent = await api.sentTestEmail(upgraded)
          statusPopup(sent['data'], 'success')
        }}
      />
    </StyledBox>
  )
}
