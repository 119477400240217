import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormControl, TextField } from '@mui/material'

const re = /\S+@\S+\.\S+/
const validateEmail = (email) => re.test(email)

export default function InputEmailField({
  handleChange = console.log,
  helperText = <FormattedMessage id="input.add_email" />,
  fieldName = 'Email',
  label = <FormattedMessage id="input.email" />,
  isFullWidth = true,
  onEnter = () => {}
}) {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [dirty, setDirty] = useState(false)

  const _handleChange = (event) => {
    const val = event.target.value

    const isVal = validateEmail(val)
    setIsValid(isVal)

    setValue(val)
    handleChange(val, isVal)
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onEnter()
    }
  }

  return (
    <React.Fragment>
      <FormControl fullWidth={isFullWidth}>
        <TextField
          error={dirty && isValid === false}
          onBlur={() => setDirty(true)}
          id={fieldName}
          label={label}
          name={fieldName}
          variant="outlined"
          size={'small'}
          helperText={helperText}
          value={value}
          InputLabelProps={{
            shrink: true
          }}
          onInput={(e) => _handleChange(e)}
          onKeyDown={keyPress}
          style={{ marginTop: 1 }}
        />
      </FormControl>
    </React.Fragment>
  )
}
