import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import styled from '@emotion/styled'
import produce from 'immer'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ContentCopy as ContentCopyIcon,
  Delete
} from '@mui/icons-material'
import api from '../../api'
import { getSurveyList } from '../../api'
import { useSnackbar } from 'notistack'
import { routes, survey_item_types } from '../../constants'
import Store from '../../store'
import { useNavigate } from 'react-router-dom'
import { ChromePicker } from 'react-color'
import RichText from '../../components/Editor'
import CompactRichText from '../../components/Editor/CompactEditor'
import { useDropzone } from 'react-dropzone'
import TagFilter from '../../components/TagFilter'
import TagSelector from '../../components/TagSelector'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FormattedMessage, useIntl } from 'react-intl'

const generateNextId = (list) => {
  if (!list || list.length === 0) return 1
  return Math.max(...list.map((list) => list.id)) + 1
}

const StyledBox = styled(Box)`
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  zIndex: 0
})

const OrderButtons = ({ id, setState }) => (
  <Box>
    <Stack>
      <ArrowDropUpIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setState(
            produce((draft) => {
              const fromIndex = draft.items.findIndex((i) => i.id === id)
              const toIndex = fromIndex - 1
              if (toIndex < 0) return

              const element = draft.items.splice(fromIndex, 1)[0]
              draft.items.splice(toIndex, 0, element)
            })
          )
        }}
      />
      <ArrowDropDownIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setState(
            produce((draft) => {
              const fromIndex = draft.items.findIndex((i) => i.id === id)
              const toIndex = fromIndex + 1
              if (toIndex === draft.items.length) return

              const element = draft.items.splice(fromIndex, 1)[0]
              draft.items.splice(toIndex, 0, element)
            })
          )
        }}
      />
    </Stack>
  </Box>
)

const ContentCopyIconButton = ({ setState, item }) => {
  return (
    <IconButton
      sx={{ color: 'primary.main' }}
      onClick={async () => {
        setState(
          produce((draft) => {
            const currentItemIndex = draft.items.findIndex(
              (i) => i.id === item.id
            )
            draft.items.splice(currentItemIndex, 0, {
              ...draft.items[currentItemIndex],
              id: generateNextId(draft.items)
            })
          })
        )
      }}>
      <ContentCopyIcon />
    </IconButton>
  )
}

const QuestionWrapper = (props) => (
  <Box
    sx={{
      borderColor: 'primary.main',
      borderStyle: 'solid',
      borderWidth: 2,
      py: 3,
      px: 2,
      my: 1,
      borderRadius: 2,
      color: 'gray'
    }}>
    {props.children}
  </Box>
)
const DeleteIcon = styled(Delete)`
  color: grey;
  cursor: pointer;
`

export const SurveyNew = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState(
    props.initial || {
      title: '',
      items: [],
      meta: {
        bgcolor: 'rgba(255,255,255,0.87)',
        fontcolor: '#05467e',
        questionbgcolor: 'rgba(255,255,255,0)',
        back_btn: 'Previous',
        next_btn: 'Next',
        submit_btn: 'Submit',
        numbering: false,
        redirect: 'https://prompta.ai',
        regards:
          'We thank you for your participation, your results have been submitted.',
        bgimage:
          'https://storage.googleapis.com/survey-engine-bucket/pexels-photo-1103970.webp',
        logo: 'https://storage.googleapis.com/survey-engine-bucket/logo.png',
        logo_left_aligned: true
      },
      is_public: false
    }
  )
  const intl = useIntl()
  const [view, setView] = useState('items')
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  const {
    acceptedFiles: acceptedFilesBG,
    getRootProps: getRootPropsBG,
    getInputProps: getInputPropsBG
  } = useDropzone()

  const setPreviewData = Store((state) => state.setPreviewData)
  const navigate = useNavigate()
  const [dirty, setDirty] = useState(false)

  const beforeunloadHandler = useCallback(
    function (e) {
      if (dirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    [dirty]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadHandler)
    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler)
    }
  }, [beforeunloadHandler])

  useEffect(() => {
    setDirty(true)
  }, [state])

  // 5MB upload maximum
  const MAX_UPLOAD_SIZE = 500000
  // Arbitrary limit
  const MAX_NUM_LOGOS = 5

  const uploadImage = async (imageFile) => {
    if (!imageFile.type.includes('image')) {
      enqueueSnackbar('Unrecognized format: file must be an image', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      return null
    }
    if (imageFile.size > MAX_UPLOAD_SIZE) {
      enqueueSnackbar(
        `File size exceeds limit: files must be under ${
          MAX_UPLOAD_SIZE / 100000
        }MB`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        }
      )
      return null
    }
    const { url } = await api.uploadImage(imageFile)
    return url
  }

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified
    })
  }

  const removeFromArray = (arr, element) => {
    const indexOf = arr.indexOf(element)
    if (indexOf < 0) return arr
    if (arr.length > 1) arr.splice(indexOf, 1)
    else arr = []
    return arr
  }

  useEffect(() => {
    ;(async () => {
      if (!acceptedFiles[0]) return
      let urls = []
      if (typeof state.meta.logo === 'string' && state.meta.logo !== '') {
        urls = [state.meta.logo]
      } else if (Array.isArray(state.meta.logo)) {
        urls = state.meta.logo
      }

      const maxNewLogos = MAX_NUM_LOGOS - (urls.length + acceptedFiles.length)
      if (maxNewLogos < 0) {
        enqueueSnackbar(
          `Upload limit exceeded: survey may only have up to ${MAX_NUM_LOGOS} logos`,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }
        )
      }

      for (let index = 0; index < maxNewLogos + 1; index++) {
        if (index >= acceptedFiles.length) break

        // File names need to be unique on our server to prevent overlap
        const fileToUpload = renameFile(
          acceptedFiles[index],
          Math.floor(Math.random() * Date.now()) +
            '-' +
            acceptedFiles[index].name
        )
        const url = await uploadImage(fileToUpload)
        if (url !== null) {
          urls = urls.concat([url])
        }
      }

      setState(
        produce((draft) => {
          draft.meta = {
            ...draft.meta,
            logo: urls
          }
        })
      )
    })()
  }, [acceptedFiles])

  useEffect(() => {
    ;(async () => {
      if (!acceptedFilesBG[0]) return
      const url = await uploadImage(acceptedFilesBG[0])
      setState(
        produce((draft) => {
          draft.meta = {
            ...draft.meta,
            bgimage: url
          }
        })
      )
    })()
  }, [acceptedFilesBG])

  const addItem = (item) => {
    setState({ ...state, items: [...state.items, item] })
  }

  const isSurveyValid = (survey) => {
    if (!survey.title.trim()) return false
    if (survey.items.length === 0) return false

    for (let item of survey.items) {
      if (item.type === 'single-choice') {
        for (let choice of item.choices) {
          if (!choice.title.trim()) return false
        }
      }

      // Some items don't have titles, such as page breaks
      if (item.title !== undefined) {
        if (typeof item.title === 'string') {
          if (item.title.trim() === '') {
            return false
          }
        } else if (item.title.length < 1) {
          return false
        } else if (item.title[0].children[0].text.trim() === '') {
          return false
        }
      }
    }
    return true
  }

  const [loading, setLoading] = useState(false)
  const saveSurvey = async () => {
    setLoading(true)
    let surveyList = await getSurveyList()
    let isUnique = true
    surveyList.forEach((survey) => {
      if (state.title === survey.title) {
        // Don't prevent updating a survey
        if (state.id !== survey.id) {
          isUnique = false
          setLoading(false)
          return
        }
      }
    })
    if (!isUnique) {
      enqueueSnackbar(<FormattedMessage id="survey.duplicate_title" />, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      setLoading(false)
      return
    }

    if (isSurveyValid(state)) {
      const savedStatus = await api.saveSurvey(state)
      const data = savedStatus.data
      if (data.created) {
        navigate(`${routes.surveys}/${data.surveyId}`)
        setState({ ...state, id: data.surveyId })
      }
      enqueueSnackbar(<FormattedMessage id="survey.saved" />, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      setDirty(false)
    } else {
      enqueueSnackbar(<FormattedMessage id="survey.missing_field" />, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
    setLoading(false)
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    )

    setState({
      ...state,
      items
    })
  }

  const ensureValidSlateValue = (value) => {
    if (!Array.isArray(value) || value.length === 0) {
      // Return a minimal valid Slate document
      return [{ type: 'paragraph', children: [{ text: value || '' }] }]
    }
    return value
  }

  const handleCheckboxChange = (event) => {
    setState({
      ...state,
      is_public: event.target.checked
    })
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <TextField
          fullWidth
          required
          label={<FormattedMessage id="survey.title" />}
          id="outlined-size-normal"
          value={state.title}
          onChange={(event) =>
            setState({ ...state, title: event.target.value })
          }
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}>
          <Button onClick={() => setView('items')}>
            <FormattedMessage id="general.items" />
          </Button>
          <Button onClick={() => setView('settings')}>
            <FormattedMessage id="general.settings" />
          </Button>
          <Tooltip
            title={
              !isSurveyValid(state) ? (
                <>
                  <Typography variant="caption" color="inherit" gutterBottom>
                    <FormattedMessage id="survey.missing_field" />
                  </Typography>
                  <br />
                  <Typography variant="caption" color="inherit" gutterBottom>
                    <FormattedMessage id="survey.missing_value" />
                  </Typography>
                </>
              ) : (
                ''
              )
            }>
            <span>
              <Button
                onClick={async () => {
                  await saveSurvey()
                  setPreviewData({
                    title: state.title,
                    meta: state.meta,
                    structure: state.items.map((i) => {
                      let item = { ...i }
                      if (item.type === survey_item_types.singleChoice) {
                        item.choices = item.choices.map((item) => ({
                          ...item,
                          pk: Math.floor(Math.random() * Date.now())
                        }))
                      }
                      if (item.type === survey_item_types.likertScale) {
                        item.scale = item.scale.map((item) => ({
                          ...item,
                          pk: Math.floor(Math.random() * Date.now())
                        }))
                      }
                      return {
                        ...item,
                        pk: Math.floor(Math.random() * Date.now())
                      }
                    })
                  })
                  navigate(`${routes.surveys}${routes.preview}`)
                }}
                disabled={!isSurveyValid(state)}>
                <FormattedMessage id="general.preview" />
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
        <Box width="23%">
          <FormattedMessage id="survey.should_be_public" />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.is_public}
              name="publicAvailability"
              color="primary"
              onChange={handleCheckboxChange}
            />
          }
          label=""
        />
      </Stack>
      {view === 'settings' && (
        <Box>
          <Typography variant="h6">
            <FormattedMessage id="survey.colours" />
          </Typography>
          <Stack direction="row" spacing={1}>
            <Box>
              <Box sx={{ my: 1 }}>
                {' '}
                <FormattedMessage id="survey.bg_colour" />:
              </Box>
              <ChromePicker
                color={state.meta?.bgcolor}
                onChangeComplete={(color) => {
                  setState(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        bgcolor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      }
                    })
                  )
                }}
              />
            </Box>
            <Box>
              <Box sx={{ my: 1 }}>
                <FormattedMessage id="survey.question_bg_colour" />:
              </Box>
              <ChromePicker
                color={state.meta?.questionbgcolor}
                onChangeComplete={(color) => {
                  setState(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        questionbgcolor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      }
                    })
                  )
                }}
              />
            </Box>
            <Box>
              <Box sx={{ my: 1 }}>
                <FormattedMessage id="survey.font_colour" />:
              </Box>
              <ChromePicker
                color={state.meta?.fontcolor}
                onChangeComplete={(color) => {
                  setState(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        fontcolor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      }
                    })
                  )
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.redirect_link" />
            </Box>
            <TextField
              fullWidth
              value={state.meta?.redirect || 'https://prompta.ai'}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = { ...draft.meta, redirect: event.target.value }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.back_button" />
            </Box>
            <TextField
              fullWidth
              value={state.meta?.back_btn}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = { ...draft.meta, back_btn: event.target.value }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.next_button" />
            </Box>
            <TextField
              fullWidth
              value={state.meta?.next_btn}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = { ...draft.meta, next_btn: event.target.value }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.submit_button" />
            </Box>
            <TextField
              fullWidth
              value={state.meta?.submit_btn}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = {
                      ...draft.meta,
                      submit_btn: event.target.value
                    }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.regards" />
            </Box>
            <TextField
              fullWidth
              value={state.meta?.regards}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = {
                      ...draft.meta,
                      regards: event.target.value
                    }
                  })
                )
              }}
            />
          </Stack>
          <Stack direction="row" sx={{ my: 3 }} spacing={3} alignItems="center">
            <Box width="30%">
              <FormattedMessage id="survey.show_numbers" />
            </Box>
            <Stack direction="row" spacing={1}>
              <Typography>
                <FormattedMessage id="general.off" />
              </Typography>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={state?.meta?.numbering}
                onChange={(event) => {
                  setState(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        numbering: event.target.checked
                      }
                    })
                  )
                }}
              />
              <Typography>
                <FormattedMessage id="general.on" />
              </Typography>
            </Stack>
          </Stack>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <FormattedMessage id="survey.upload_logo" />:
              </Box>
            </Stack>
            <Box>
              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>
                    <FormattedMessage id="input.drag_drop_image" />
                  </p>
                </div>
              </section>
            </Box>
          </StyledBox>
          <Box style={{ display: 'flex' }}>
            {Array.isArray(state.meta.logo) ? (
              state.meta.logo.map((image) => (
                <Box
                  style={{ width: '20%', display: 'flex', marginTop: '10px' }}>
                  <img
                    style={{ width: '100%', height: 'max-content' }}
                    src={image}
                    alt=""
                  />
                  <DeleteIcon
                    onClick={() => {
                      setState(
                        produce((draft) => {
                          draft.meta = {
                            ...draft.meta,
                            logo: removeFromArray(draft.meta.logo, image)
                          }
                        })
                      )
                    }}
                  />
                </Box>
              ))
            ) : (
              <Box style={{ width: '20%', display: 'flex', marginTop: '10px' }}>
                <img style={{ width: '100%' }} src={state.meta.logo} alt="" />
                <DeleteIcon
                  onClick={() => {
                    setState(
                      produce((draft) => {
                        draft.meta = {
                          ...draft.meta,
                          logo: []
                        }
                      })
                    )
                  }}
                />
              </Box>
            )}
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: 'center', marginBottom: '20px' }}>
            <FormattedMessage id="survey.logo_alignment" />:<br />
            <Typography>
              <FormattedMessage id="general.left" />
            </Typography>
            <Switch
              checked={state.logo_left_aligned}
              onChange={(event) => {
                setState(
                  produce((draft) => {
                    draft.meta = {
                      ...draft.meta,
                      logo_left_aligned: event.target.checked
                    }
                  })
                )
              }}
            />
            <Typography>
              <FormattedMessage id="general.right" />
            </Typography>
          </Stack>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <FormattedMessage id="survey.upload_bg" />:
              </Box>
            </Stack>
            <Box>
              <section className="container">
                <div {...getRootPropsBG({ className: 'dropzone' })}>
                  <input {...getInputPropsBG()} />
                  <p>
                    <FormattedMessage id="input.drag_drop_image" />
                  </p>
                </div>
              </section>
            </Box>
          </StyledBox>
          {state?.meta?.bgimage && (
            <Box style={{ width: '70%', display: 'flex', marginTop: '10px' }}>
              <img style={{ width: '100%' }} src={state.meta?.bgimage} alt="" />
              <DeleteIcon
                onClick={() => {
                  setState(
                    produce((draft) => {
                      draft.meta = {
                        ...draft.meta,
                        bgimage: ''
                      }
                    })
                  )
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {view === 'items' && state.items && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {state.items.map((item, index) => {
                  const getContent = (item) => {
                    if (item.type === survey_item_types.text) {
                      return (
                        <QuestionWrapper key={item.id}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}>
                            <OrderButtons id={item.id} setState={setState} />
                            <CompactRichText
                              fontColor={
                                state.meta.fontcolor || 'rgba(0, 0, 0, 1)'
                              }
                              value={ensureValidSlateValue(item.title)}
                              setValue={(value) => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.title = value
                                  })
                                )
                              }}
                            />
                            <ContentCopyIconButton
                              item={item}
                              setState={setState}></ContentCopyIconButton>
                            <DeleteIcon
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentIndex = draft.items.findIndex(
                                      (i) => i.id === item.id
                                    )
                                    draft.items.splice(currentIndex, 1)
                                  })
                                )
                              }}
                            />
                          </Stack>
                          <TagSelector setState={setState} item={item} />
                          <Box sx={{ p: 1, my: 2 }}>
                            <RichText
                              value={item.content}
                              setValue={(value) => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.content = value
                                  })
                                )
                              }}
                            />
                          </Box>
                        </QuestionWrapper>
                      )
                    } else if (item.type === survey_item_types.pageBreak) {
                      return (
                        <QuestionWrapper key={item.id}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}>
                            <OrderButtons id={item.id} setState={setState} />
                            <Box>
                              <FormattedMessage id="questions.page_break" />
                            </Box>
                            <ContentCopyIconButton
                              item={item}
                              setState={setState}></ContentCopyIconButton>
                            <DeleteIcon
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentIndex = draft.items.findIndex(
                                      (i) => i.id === item.id
                                    )
                                    draft.items.splice(currentIndex, 1)
                                  })
                                )
                              }}
                            />
                          </Stack>
                        </QuestionWrapper>
                      )
                    } else if (item.type === survey_item_types.openText) {
                      return (
                        <QuestionWrapper key={item.id}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}>
                            <OrderButtons id={item.id} setState={setState} />
                            <CompactRichText
                              value={ensureValidSlateValue(item.title)}
                              setValue={(value) => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.title = value
                                  })
                                )
                              }}
                            />
                            <ContentCopyIconButton
                              item={item}
                              setState={setState}
                            />
                            <DeleteIcon
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentIndex = draft.items.findIndex(
                                      (i) => i.id === item.id
                                    )
                                    draft.items.splice(currentIndex, 1)
                                  })
                                )
                              }}
                            />
                          </Stack>
                          <TagSelector setState={setState} item={item} />
                        </QuestionWrapper>
                      )
                    } else if (item.type === survey_item_types.singleChoice) {
                      return (
                        <QuestionWrapper key={item.id}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}>
                            <div style={{ marginTop: '30px' }}>
                              <OrderButtons id={item.id} setState={setState} />
                            </div>
                            <div style={{ width: '100%' }}>
                              <CompactRichText
                                value={ensureValidSlateValue(item.title)}
                                setValue={(value) => {
                                  setState(
                                    produce((draft) => {
                                      const currentItem = draft.items.find(
                                        (i) => i.id === item.id
                                      )
                                      currentItem.title = value
                                    })
                                  )
                                }}
                              />
                            </div>
                            <div style={{ marginTop: '30px' }}>
                              <ContentCopyIconButton
                                item={item}
                                setState={setState}
                              />
                            </div>
                            <DeleteIcon
                              style={{ marginTop: '30px' }}
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentIndex = draft.items.findIndex(
                                      (i) => i.id === item.id
                                    )
                                    draft.items.splice(currentIndex, 1)
                                  })
                                )
                              }}
                            />
                          </Stack>
                          <TagSelector setState={setState} item={item} />

                          <Box sx={{ p: 1, my: 2 }}>
                            <RichText
                              value={ensureValidSlateValue(item.content)}
                              setValue={(value) => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.content = value
                                  })
                                )
                              }}
                            />
                          </Box>
                          <FormControl fullWidth>
                            <FormLabel id="demo-radio-buttons-group-label">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                sx={{ my: 1 }}>
                                {item.choices.map((choice, index) => {
                                  return (
                                    <Box
                                      sx={{ display: 'flex', my: 0.5 }}
                                      key={choice.id}>
                                      <FormControlLabel
                                        value={choice.title}
                                        control={<Radio />}
                                      />
                                      <Box>
                                        <Stack
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="center"
                                          spacing={1}>
                                          <TextField
                                            value={choice.title}
                                            onChange={(event) => {
                                              setState(
                                                produce((draft) => {
                                                  const currentChoice =
                                                    draft.items
                                                      .find(
                                                        (i) => i.id === item.id
                                                      )
                                                      .choices.find(
                                                        (i) =>
                                                          i.id === choice.id
                                                      )
                                                  currentChoice.title =
                                                    event.target.value
                                                })
                                              )
                                            }}
                                          />
                                          {index > 1 && (
                                            <DeleteIcon
                                              onClick={() => {
                                                setState(
                                                  produce((draft) => {
                                                    const currentItem =
                                                      draft.items.find(
                                                        (i) => i.id === item.id
                                                      )
                                                    const currentChoiceIndex =
                                                      currentItem.choices.findIndex(
                                                        (i) =>
                                                          i.id === choice.id
                                                      )
                                                    currentItem.choices.splice(
                                                      currentChoiceIndex,
                                                      1
                                                    )
                                                  })
                                                )
                                              }}
                                            />
                                          )}
                                          <TagFilter
                                            setState={setState}
                                            item={item}
                                            choice={choice}
                                          />
                                        </Stack>
                                      </Box>
                                    </Box>
                                  )
                                })}
                              </RadioGroup>
                            </FormLabel>
                            <Button
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.choices.push({
                                      title: '',
                                      id: generateNextId(currentItem.choices)
                                    })
                                  })
                                )
                              }}>
                              <FormattedMessage id="questions.add_choice" />
                            </Button>
                          </FormControl>
                        </QuestionWrapper>
                      )
                    } else if (item.type === survey_item_types.likertScale) {
                      return (
                        <QuestionWrapper key={item.id}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}>
                            <div style={{ marginTop: '30px' }}>
                              <OrderButtons id={item.id} setState={setState} />
                            </div>
                            <div style={{ width: '100%' }}>
                              <CompactRichText
                                value={ensureValidSlateValue(item.title)}
                                setValue={(value) => {
                                  setState(
                                    produce((draft) => {
                                      const currentItem = draft.items.find(
                                        (i) => i.id === item.id
                                      )
                                      currentItem.title = value
                                    })
                                  )
                                }}
                              />
                            </div>
                            <div style={{ marginTop: '30px' }}>
                              <ContentCopyIconButton
                                item={item}
                                setState={setState}
                              />
                            </div>
                            <DeleteIcon
                              style={{ marginTop: '30px' }}
                              onClick={() => {
                                setState(
                                  produce((draft) => {
                                    const currentIndex = draft.items.findIndex(
                                      (i) => i.id === item.id
                                    )
                                    draft.items.splice(currentIndex, 1)
                                  })
                                )
                              }}
                            />
                          </Stack>
                          <TagSelector setState={setState} item={item} />
                          <Box sx={{ p: 1, my: 2 }}>
                            <RichText
                              value={ensureValidSlateValue(item.content)}
                              setValue={(value) => {
                                setState(
                                  produce((draft) => {
                                    const currentItem = draft.items.find(
                                      (i) => i.id === item.id
                                    )
                                    currentItem.content = value
                                  })
                                )
                              }}
                            />
                          </Box>
                          <FormControl style={{ width: '100%' }}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-form-control-label-placement"
                              name="position"
                              defaultValue="top">
                              {item.scale.map((option) => (
                                <Box style={{ width: '20%' }}>
                                  <FormControlLabel
                                    value={item.scale.indexOf(option.title)}
                                    control={<Radio />}
                                    label={option.title}
                                    labelPlacement="bottom"
                                    style={{
                                      alignContent: 'center',
                                      textAlign: 'center',
                                      width: '100%',
                                      margin: 0
                                    }}
                                    disabled
                                  />
                                </Box>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </QuestionWrapper>
                      )
                    }
                  }

                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          {getContent(item)}
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {view === 'items' && (
        <Box
          sx={{
            borderColor: 'success.main',
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 2,
            p: 2,
            my: 2
          }}>
          <Typography variant="subtitle1">
            <FormattedMessage id="questions.add_question" />
          </Typography>
          <Button
            color="success"
            onClick={() =>
              addItem({
                id: generateNextId(state.items),
                type: survey_item_types.singleChoice,
                title: '',
                choices: [
                  {
                    title: 'choice 1',
                    id: 1
                  },
                  {
                    title: 'choice 2',
                    id: 2
                  }
                ]
              })
            }>
            <FormattedMessage id="questions.single_choice" />
          </Button>
          <Button
            color="success"
            onClick={() =>
              addItem({
                id: generateNextId(state.items),
                type: survey_item_types.likertScale,
                title: '',
                scale: [
                  {
                    title: intl.formatMessage({ id: 'likert.s_disagree' }),
                    id: 1
                  },
                  {
                    title: intl.formatMessage({ id: 'likert.disagree' }),
                    id: 2
                  },
                  {
                    title: intl.formatMessage({ id: 'likert.neutral' }),
                    id: 3
                  },
                  { title: intl.formatMessage({ id: 'likert.agree' }), id: 4 },
                  { title: intl.formatMessage({ id: 'likert.s_agree' }), id: 5 }
                ]
              })
            }>
            <FormattedMessage id="questions.likert_scale" />
          </Button>
          <Button
            color="success"
            onClick={() =>
              addItem({
                id: generateNextId(state.items),
                type: survey_item_types.openText,
                title: ''
              })
            }>
            Open-Text
          </Button>
          <Button
            color="success"
            onClick={() =>
              addItem({
                id: generateNextId(state.items),
                type: survey_item_types.pageBreak
              })
            }>
            <FormattedMessage id="questions.page_break" />
          </Button>
          <Button
            color="success"
            onClick={() =>
              addItem({
                id: generateNextId(state.items),
                type: survey_item_types.text,
                header: '',
                content: [
                  {
                    type: 'paragraph',
                    children: [{ text: '' }]
                  }
                ]
              })
            }>
            <FormattedMessage id="questions.text_content" />
          </Button>
        </Box>
      )}
      {/*todo: https://mui.com/material-ui/react-button/#loading-button*/}
      <Tooltip
        title={
          !isSurveyValid(state) ? (
            <>
              <Typography variant="caption" color="inherit" gutterBottom>
                <FormattedMessage id="survey.missing_field" />
              </Typography>
              <br />
              <Typography variant="caption" color="inherit" gutterBottom>
                <FormattedMessage id="survey.missing_value" />
              </Typography>
            </>
          ) : (
            ''
          )
        }>
        <span>
          <Button
            variant="contained"
            color="success"
            onClick={saveSurvey}
            disabled={!isSurveyValid(state) || loading}>
            {loading ? (
              <CircularProgress sx={{ color: '#00438e' }} size={18} />
            ) : (
              <FormattedMessage id="general.save" />
            )}
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}
