import * as React from 'react'
import { Box, CircularProgress } from '@mui/material'

export default function Loading(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.boxSx
      }}>
      <CircularProgress sx={{ color: props.color || '#00438e' }} />
    </Box>
  )
}
