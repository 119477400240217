import React, { useState, useEffect } from 'react'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../constants'
import { useSnackbar } from 'notistack'
import Store from '../../store'
import useStore from '../../store'
import RespondentCountTags from '../../components/RespondentCountTags'
import SimpleDialog from '../../components/SimpleDialog'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Tooltip
} from '@mui/material'
import {
  AlternateEmail as AlternateEmailIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Lock as LockIcon
} from '@mui/icons-material'

const EditIconButton = (props) => {
  const navigate = useNavigate()

  return (
    <Tooltip title={<FormattedMessage id="tooltip.edit_survey" />}>
      <IconButton
        sx={{ color: 'primary.main' }}
        onClick={() => navigate(`${routes.surveys}/${props.id}`)}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}

const LockIconButton = () => {
  return (
    <Tooltip title={<FormattedMessage id="tooltip.survey_released" />}>
      <IconButton sx={{ color: 'text.disabled' }}>
        <LockIcon />
      </IconButton>
    </Tooltip>
  )
}

const DeleteIconButton = (props) => {
  return (
    <Tooltip title={<FormattedMessage id="survey.delete_survey" />}>
      <IconButton sx={{ color: 'error.main' }} onClick={props.onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

export const SurveyList = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [secondary, setSecondary] = useState(false)
  const [dialog, setDialog] = useState({ open: false })
  const surveys = useStore((state) => state.surveys)
  const currentPage = useStore((state) => state.currentPage)
  const rowsPerPage = useStore((state) => state.rowsPerPage)
  const totalItems = useStore((state) => state.totalItems)
  const setSurveys = useStore((state) => state.setSurveys)
  const setCurrentPage = useStore((state) => state.setCurrentPage)
  const setRowsPerPage = useStore((state) => state.setRowsPerPage)
  const setTotalItems = useStore((state) => state.setTotalItems)

  const updateSurveyPage = async () => {
    await api.getSurveyListPage(currentPage + 1, rowsPerPage).then((data) => {
      setSurveys(data.items)
      setTotalItems(data.total_items)
    })
  }

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage)
    // TablePagination is 0-indexed, but the api call is 1-indexed, so we must offset
    await api.getSurveyListPage(newPage + 1, rowsPerPage).then((data) => {
      setSurveys(data.items)
      setTotalItems(data.total_items)
    })
  }

  const handleChangeRowsPerPage = async (event) => {
    let rowsPer = parseInt(event.target.value)
    setRowsPerPage(rowsPer)
    setCurrentPage(0)
    // Set functions are async, so using constants makes sure we use the most up to date data
    await api.getSurveyListPage(1, rowsPer).then((data) => {
      setSurveys(data.items)
      setTotalItems(data.total_items)
    })
  }

  useEffect(() => {
    updateSurveyPage()
  }, [])

  const CollectButton = (props) => {
    const navigate = useNavigate()

    return (
      <Tooltip title={<FormattedMessage id="tooltip.view_collectors" />}>
        <IconButton
          sx={{ color: 'primary.main' }}
          onClick={() => {
            Store.setState({
              currentSurvey: props.id,
              surveys: surveys,
              surveyPage: currentPage + 1,
              surveysPerPage: rowsPerPage
            })
            navigate(`${routes.collect}/${props.id}`)
          }}>
          <AlternateEmailIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const ContentCopyIconButton = (props) => {
    const [loading, setLoading] = useState(false)

    return (
      <Tooltip title={<FormattedMessage id="tooltip.duplicate_survey" />}>
        <IconButton
          sx={{ color: 'primary.main' }}
          onClick={async () => {
            setLoading(true)
            await api.duplicateSurvey(props.id)
            updateSurveyPage()
            setLoading(false)
          }}>
          {loading ? <CircularProgress size={20} /> : <ContentCopyIcon />}
        </IconButton>
      </Tooltip>
    )
  }

  const ListDisplay = () => {
    return (
      <Box>
        <List>
          {surveys.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                bgcolor: 'rgb(232,232,232)',
                borderRadius: 2,
                my: 1
              }}>
              <ListItemText
                primary={item.title}
                secondary={secondary ? item.description : null}
              />

              <RespondentCountTags item={item} />
              <CollectButton id={item.id} />
              {!item.released ? (
                <EditIconButton id={item.id} />
              ) : (
                <LockIconButton />
              )}
              <ContentCopyIconButton id={item.id} />
              <DeleteIconButton
                id={item.id}
                onClick={() =>
                  setDialog({
                    ...dialog,
                    open: true,
                    title: item.title,
                    id: item.id
                  })
                }
              />
            </ListItem>
          ))}
        </List>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box>
          <FormattedMessage id="survey.list" />
        </Box>
        <Button
          variant="contained"
          onClick={() => navigate(`${routes.surveys}${routes.surveyNew}`)}>
          <FormattedMessage id="survey.add_new" />
        </Button>
      </Box>
      <ListDisplay />
      <SimpleDialog
        content={<FormattedMessage id="survey.confirm_delete" />}
        onConfirm={async () => {
          try {
            await api.deleteSurvey(dialog.id)
            updateSurveyPage()
            enqueueSnackbar(<FormattedMessage id="survey.survey_deleted" />, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            })
            setDialog({ open: false })
          } catch (error) {
            console.error('Error deleting survey', error)
          }
        }}
        open={dialog.open}
        title={<FormattedMessage id="survey.delete_survey" />}
        setOpen={(open) => setDialog({ ...dialog, open })}
      />
    </Box>
  )
}
