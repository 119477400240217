import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { IntlProvider } from 'react-intl'
import English from './lang/en.json'
import Spanish from './lang/es.json'

const messages = {
  en: English,
  es: Spanish
}

export const Root = () => {
  const [locale, setLocale] = useState('en')
  const [translations, setTranslations] = useState(messages['en'])

  const handleLocaleChange = (newLocale) => {
    newLocale = newLocale in messages ? newLocale : 'en'
    let messageSet =
      newLocale === 'en'
        ? messages['en']
        : Object.assign({}, messages['en'], messages[newLocale])

    setTranslations(messageSet)
    setLocale(locale)
  }

  return (
    <IntlProvider locale={locale} messages={translations}>
      <App locale={locale} setLocale={handleLocaleChange} />
    </IntlProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
