import { useEffect, useState } from 'react'
import api from '../../api'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import {
  BarChart as BarChartIcon,
  PeopleAlt as PeopleIcon
} from '@mui/icons-material'
import { BarChart } from '@mui/x-charts'
import Loading from '../../components/Loading'
import { chart_types } from '../../constants'

export const ChartOverview = () => {
  const [collectors, setCollectors] = useState([])
  const [questions, setQuestions] = useState([])
  const [responses, setResponses] = useState([])

  const [currentQuestion, setCurrentQuestion] = useState(null)
  // The questions whose data is being displayed at the same time
  const [currentCollector, setCurrentCollector] = useState(null)
  const [chartType, setChartType] = useState(chart_types.engagement)
  const intl = useIntl()
  const selectedColor = '#e9e9e9'

  useEffect(() => {
    const loadData = async () => {
      const data = await api.getCollectorsForUser()
      const mergedList = mergeCollectors(data)
      setCollectors(mergedList)
    }
    loadData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      let allData = await api.getResponsesForCollector(
        currentCollector.survey_id[0],
        currentCollector.collector_id[0]
      )
      let allQuestions = allData.questions
      let allResponses = allData.responses

      for (let i = 1; i < currentCollector.survey_id.length; i++) {
        const data = await api.getResponsesForCollector(
          currentCollector.survey_id[i],
          currentCollector.collector_id[i]
        )
        for (let question of data.questions) {
          allQuestions.push(question)
        }
        for (let key of Object.keys(data.responses)) {
          allResponses[key] = data.responses[key]
        }
      }
      setQuestions(allQuestions)
      setResponses(allResponses)
    }

    if (currentCollector) {
      getData()
      setCurrentQuestion(null)
    }
  }, [currentCollector])

  // Combines the data of collectors that share a name
  function mergeCollectors(collectorList) {
    let newList = []
    for (let i = 0; i < collectorList.length; i++) {
      const collectorName = collectorList[i].name
      let sharesNameIndex = []
      for (let j = i + 1; j < collectorList.length; j++) {
        if (collectorName === collectorList[j].name) {
          sharesNameIndex.push(j)
        }
      }

      // The same, except now the id fields are arrays
      let newCollector = {
        name: collectorName,
        collector_id: [collectorList[i].collector_id],
        engagement: collectorList[i].engagement,
        survey_id: [collectorList[i].survey_id]
      }

      if (sharesNameIndex.length > 0) {
        // This is so that removing a collector doesn't change the index of the
        // other collectors we want to access
        sharesNameIndex.reverse()

        for (let index = 0; index < sharesNameIndex.length; index++) {
          const otherCollector = collectorList.splice(
            [sharesNameIndex[index]],
            1
          )[0]

          newCollector.collector_id.push(otherCollector.collector_id)
          newCollector.survey_id.push(otherCollector.survey_id)

          const otherEngagement = otherCollector.engagement
          newCollector.engagement['Completed'] += otherEngagement['Completed']
          newCollector.engagement['Opened'] += otherEngagement['Opened']
          newCollector.engagement['Ignored'] += otherEngagement['Ignored']
        }
      }
      newList.push(newCollector)
    }
    return newList
  }

  const ChartTypeSelector = () => {
    return (
      <Box display="flex" mb="5px">
        <Tooltip title={<FormattedMessage id="tooltip.engagement" />}>
          <IconButton
            sx={{
              backgroundColor:
                chartType === chart_types.engagement ? selectedColor : '',
              marginRight: '1px'
            }}
            onClick={() => {
              setChartType(chart_types.engagement)
            }}>
            <PeopleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="tooltip.bar_chart" />}>
          <IconButton
            sx={{
              backgroundColor:
                chartType === chart_types.barChart ? selectedColor : '',
              marginRight: '1px'
            }}
            onClick={() => {
              setChartType(chart_types.barChart)
            }}>
            <BarChartIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  // Handles the display of all the charts
  const ResponseChart = () => {
    if (chartType === chart_types.engagement) {
      if (collectors.length === 0) {
        return <FormattedMessage id="charts.no_data" />
      }

      let completed = []
      let opened = []
      let unopened = []
      let collectorLabels = []

      if (currentCollector) {
        completed.push(currentCollector.engagement['Completed'])
        opened.push(currentCollector.engagement['Opened'])
        unopened.push(currentCollector.engagement['Ignored'])
        collectorLabels.push(currentCollector.name)
      } else {
        for (const collector of collectors) {
          const collectorEngagement = collector.engagement
          completed.push(collectorEngagement['Completed'])
          opened.push(collectorEngagement['Opened'])
          unopened.push(collectorEngagement['Ignored'])
          collectorLabels = collectors.map((collector) => collector.name)
        }
      }

      return (
        <Box>
          <BarChart
            layout="horizontal"
            barLabel="value"
            height={300}
            margin={{ left: 150, top: 50 }}
            series={[
              {
                id: 'completed',
                data: completed,
                label: intl.formatMessage({ id: 'charts.completed' }),
                stack: 'stack'
              },
              {
                id: 'opened',
                data: opened,
                label: intl.formatMessage({ id: 'charts.opened' }),
                stack: 'stack'
              },
              {
                id: 'unopened',
                data: unopened,
                label: intl.formatMessage({ id: 'charts.unopened' }),
                stack: 'stack'
              }
            ]}
            bottomAxis={null}
            yAxis={[
              {
                id: 'collectors',
                type: 'continuous',
                data: collectorLabels,
                scaleType: 'band'
              }
            ]}
          />
        </Box>
      )
    }

    if (!currentQuestion) {
      return <FormattedMessage id="charts.select_question" />
    }

    if (chartType === chart_types.barChart) {
      // All possible responses
      let options = currentQuestion.choices.map((c) => c.title)
      // Actual responses
      let answers = responses[currentQuestion.id]
      let currValues = new Array(options.length).fill(0)

      if (answers) {
        for (let i = 0; i < options.length; i++) {
          if (Object.keys(answers).includes(options[i])) {
            currValues[i] = answers[options[i]]
          }
        }
      }

      let dataSet = { data: currValues, label: currentCollector.name }

      return (
        <Box>
          <Box sx={{ height: '20px' }}>
            <Typography
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
                textAlign: 'center'
              }}>
              {currentQuestion.question}
            </Typography>
          </Box>
          <BarChart
            series={[dataSet]}
            height={290}
            xAxis={[{ data: options, scaleType: 'band' }]}
            grid={{ vertical: true, horizontal: true }}
            margin={{ top: 40, bottom: 30, left: 40, right: 10 }}
          />
        </Box>
      )
    }
  }

  const handleQuestionChange = (_event, newQuestion) => {
    if (newQuestion) {
      const questionObj = questions.filter((q) => {
        return q.question === newQuestion
      })[0]

      setCurrentQuestion(questionObj)
      if (chartType === chart_types.engagement) {
        setChartType(chart_types.barChart)
      }
    }
  }

  // Add a collector to the list of displayed collectors
  const handleCollectorChange = (_event, newCollector) => {
    if (newCollector) {
      const collectorObj = collectors.filter((c) => {
        return c.name === newCollector
      })[0]

      setCurrentCollector(collectorObj)
      if (chartType !== chart_types.engagement) {
        setChartType(chart_types.engagement)
      }
    }
  }

  return (
    <Box>
      {!questions ? (
        <Loading />
      ) : (
        <>
          <ChartTypeSelector />
          <Box display={'flex'}>
            <Box sx={{ width: '40%', marginRight: '5px' }}>
              <Autocomplete
                options={collectors.map((c) => c.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'general.collectors'
                    })}
                  />
                )}
                onChange={handleCollectorChange}
                disablePortal
              />
            </Box>
            <Box sx={{ width: '60%' }}>
              <Autocomplete
                options={questions.map((q) => q.question)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'questions.question'
                    })}
                  />
                )}
                onChange={handleQuestionChange}
                disablePortal
              />
            </Box>
          </Box>
          <Box>
            <ResponseChart />
          </Box>
        </>
      )}
    </Box>
  )
}
