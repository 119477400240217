import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SurveyNew } from './SurveyNew'
import Loading from '../../components/Loading'
import api from '../../api'

export const SurveyEdit = () => {
  const { surveyId } = useParams()
  const [survey, setSurvey] = useState(null)

  useEffect(() => {
    ;(async function () {
      const survey = await api.getSurvey(surveyId)
      setSurvey(survey)
    })()
  }, [])

  if (!survey) {
    return <Loading />
  }

  return <SurveyNew initial={survey} />
}
