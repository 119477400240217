import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { FormattedMessage } from 'react-intl'

export default function SimpleDialog({
  open,
  setOpen,
  content,
  onConfirm,
  title,
  cancel = <FormattedMessage id="general.cancel" />,
  ok = <FormattedMessage id="general.ok" />
}) {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancel && <Button onClick={handleClose}>{cancel}</Button>}
          <Button onClick={onConfirm} autoFocus>
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
