import React, { useState } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Radio,
  FormControlLabel
} from '@mui/material'

export default function RadioList({
  choices,
  onSelect = () => console.log,
  checkedColor,
  preChecked
}) {
  const [checked, setChecked] = useState({ pk: preChecked })

  const handleToggle = (value) => () => {
    setChecked(value)
    onSelect(value)
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'theme.palette.background.paper',
        '.Mui-checked': {
          color: `${checkedColor} !important`
        }
      }}>
      <List>
        {choices.map((choice) => (
          <ListItem
            key={choice.id}
            role={undefined}
            button
            onClick={handleToggle(choice)}
            className={'listItem'}>
            <FormControlLabel
              control={<Radio />}
              checked={!!checked && checked.pk === choice.pk}
              tabIndex={-1}
            />
            <ListItemText primary={choice.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
