import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material'
import {
  ArrowBack as ArrowBackIcon,
  AttachEmail as AttachEmailIcon,
  BarChart as BarChartIcon,
  Logout as LogoutIcon,
  Token as TokenIcon,
  ViewAgenda as ViewAgendaIcon
} from '@mui/icons-material'
import useStore from '../store'
import { isResponseOk } from '../helpers'
import { useNavigate } from 'react-router-dom'
import { routes } from '../constants'
import api from '../api'
import SimpleDialog from './SimpleDialog'
import LanguageSelector from './LanguageSelector'

const drawerWidth = 240

export default function ClippedDrawer(props) {
  const setIsAuthenticated = useStore((state) => state.setIsAuthenticated)
  const [dialog, setDialog] = React.useState({ open: false })
  const currentSurvey = useStore((state) => state.currentSurvey)
  const navigate = useNavigate()

  const logout = () => {
    fetch('/user/logout/', {
      credentials: 'same-origin'
    })
      .then(isResponseOk)
      .then((data) => {
        setIsAuthenticated(false)
        navigate(routes.login)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <LanguageSelector locale={props.locale} setLocale={props.setLocale} />
          <Typography variant="h6" noWrap component="div">
            <FormattedMessage id="general.site_header" />
          </Typography>
          <ArrowBackIcon onClick={() => navigate(-1)} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem key={'Surveys'} disablePadding>
              <ListItemButton
                onClick={() => {
                  useStore.setState({ currentSurvey: null })
                  navigate(`${routes.surveys}`)
                }}>
                <ListItemIcon>
                  <ViewAgendaIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="general.surveys" />}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Graphs'} disablePadding>
              <ListItemButton onClick={() => navigate(`${routes.chart}`)}>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="general.chart_data" />}
                />
              </ListItemButton>
            </ListItem>
            {currentSurvey && (
              <ListItem key={'Collectors'} disablePadding>
                <ListItemButton
                  onClick={() =>
                    navigate(`${routes.collect}/${currentSurvey}`)
                  }>
                  <ListItemIcon>
                    <AttachEmailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<FormattedMessage id="general.collect_survey" />}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider />
          <List>
            <ListItem key={'Logout'} disablePadding>
              <ListItemButton
                onClick={async () => {
                  const token = await api.getToken()
                  setDialog({ ...dialog, open: true, token })
                }}>
                <ListItemIcon>
                  <TokenIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="general.get_token" />}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Logout'} disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="login.logout" />}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {props.children}
      </Box>
      <SimpleDialog
        content={
          <FormattedMessage
            id="general.your_token"
            values={{ token: dialog.token }}
          />
        }
        onConfirm={async () => {
          setDialog({ ...dialog, open: false })
        }}
        cancel={false}
        open={dialog.open}
        title={''}
        setOpen={(open) => setDialog({ ...dialog, open })}
      />
    </Box>
  )
}
