import React from 'react'
import Drawer from '../../components/Drawer'
import { Outlet } from 'react-router-dom'

const SurveyPage = ({ locale, setLocale }) => {
  return (
    <Drawer locale={locale} setLocale={setLocale}>
      <Outlet />
    </Drawer>
  )
}

export default SurveyPage
