// https://github.com/pmndrs/zustand
import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

const useStore = create(
  subscribeWithSelector((set) => ({
    currentSurvey: null,
    csrf: '',
    surveys: [],
    surveyPage: 0,
    surveysPerPage: 0,
    new: {},
    isAuthenticated: false,
    previewData: null,
    answers: {},
    currentPage: 0,
    rowsPerPage: 10,
    totalItems: 0,
    setAnswers: (val) => set({ answers: val }),
    setPreviewData: (val) => set({ previewData: val }),
    setIsAuthenticated: (val) => set({ isAuthenticated: !!val }),
    setCurrentSurvey: (survey) => set({ currentSurvey: survey }),
    setSurveys: (surveys) => set({ surveys }),
    setCurrentPage: (page) => set({ currentPage: page }),
    setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
    setTotalItems: (total) => set({ totalItems: total })
  }))
)

export default useStore
